// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your pages
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import CourtsPage from './pages/CourtsPage';
import CourtQueuePage from './pages/CourtQueuePage';
import SignUpPage from './pages/SignUpPage';
import AdminAccessPage from './pages/AdminAccessPage';
import AdminDashboard from './pages/AdminDashboard';
import ContactUsPage from './pages/ContactUsPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

// Import your navigation component
import NavigationBar from './components/NavigationBar';

function App() {
  return (
    <Router>
      {/* Navigation bar displayed on all pages */}
      <NavigationBar />

      {/* Define routes for different pages */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/courts" element={<CourtsPage />} />
        <Route path="/courts/:courtId" element={<CourtQueuePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/admin" element={<AdminAccessPage />} />
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      </Routes>
    </Router>
  );
}

export default App;
