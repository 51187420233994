// src/pages/AboutPage.js
import React from 'react';
import { Container, Typography, Grid, Box, Avatar, Paper } from '@mui/material';

function AboutPage() {
  return (
    <Container maxWidth="md" sx={{ py: 5 }}>
      <Typography variant="h3" gutterBottom align="center">
        About Us
      </Typography>
      <Typography variant="h6" gutterBottom align="center" color="textSecondary">
        The Story Behind Fives
      </Typography>
      <Typography variant="body1" paragraph align="center">
        As students at the University of Michigan, Liam and Avi experienced firsthand the frustrations of pickup basketball: inconsistent court availability, unknown wait times, difficulty forming teams, and disputes over who's next. Passionate about basketball, they envisioned a solution to enhance the experience for everyone.
      </Typography>

      {/* Founders Section */}
      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box textAlign="center">
              <Avatar
                alt="Liam"
                src="/images/liam.jpg" // Replace with actual images
                sx={{ width: 120, height: 120, margin: '0 auto' }}
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Liam
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Co-Founder
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
              Passionate about basketball and technology, Liam is driven to create innovative solutions that make playing seamless and enjoyable.
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Box textAlign="center">
              <Avatar
                alt="Avi"
                src="/images/avi.jpg" // Replace with actual images
                sx={{ width: 120, height: 120, margin: '0 auto' }}
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Avi
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Co-Founder
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                A Manager for the Michigan Team and a lifelong lover of basketball Avi is dedicated to improving the game for all players.
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutPage;
