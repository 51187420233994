import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';

// Steps for the Stepper component
const steps = ['Choose Courts', 'Your Details', 'Payment'];

function SignUpPage() {
  // State for multi-step form
  const [activeStep, setActiveStep] = useState(0);

  // State for user details
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    organization: '',
    username: '',
    password: '',
  });

  // State for selected plan
  const [selectedPlan, setSelectedPlan] = useState({
    courtCount: 1,
    paidCourts: 1,
    freeCourts: 0,
    price: 29.95,
  });

  // Handle input changes for user details
  const handleUserDetailsChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  // Proceed to next step
  const handleNext = () => {
    if (activeStep === 0) {
      // Validate court count before proceeding
      if (selectedPlan.courtCount < 1 || !Number.isInteger(selectedPlan.courtCount)) {
        alert('Please enter a valid number of courts (positive integer).');
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Go back to previous step
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Handle court count change
  const handleCourtCountChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= 1 && Number.isInteger(value)) {
      const freeCourts = Math.floor(value / 5);
      const paidCourts = value - freeCourts;
      const totalPrice = (paidCourts * 29.95).toFixed(2);
      setSelectedPlan({
        courtCount: value,
        paidCourts,
        freeCourts,
        price: totalPrice,
      });
    } else {
      // Reset to default if invalid input
      setSelectedPlan({
        courtCount: value || '',
        paidCourts: 0,
        freeCourts: 0,
        price: 0,
      });
    }
  };

  // Handle Paddle Checkout
  const handlePaddleCheckout = async () => {
    try {
      const response = await fetch('/create-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userDetails.email, // Use user's email for the checkout session
          courtCount: selectedPlan.courtCount,
          price: selectedPlan.price,
        }),
      });

      const data = await response.json();
      window.location.href = data.url; // Redirect to Paddle checkout page
    } catch (error) {
      console.error('Error creating Paddle checkout:', error);
      alert('Failed to initiate checkout. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      {/* Stepper Component */}
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step Content */}
      {activeStep === 0 && (
        // Step 1: Choose Number of Courts
        <Box>
          <Typography variant="h4" gutterBottom align="center">
            Choose Number of Courts
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Every 5 courts you get 1 court free!
          </Typography>
          <TextField
            label="Number of Courts"
            type="number"
            value={selectedPlan.courtCount}
            onChange={handleCourtCountChange}
            fullWidth
            required
            sx={{ my: 3 }}
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
          {selectedPlan.courtCount >= 1 && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1">
                Paid Courts: <strong>{selectedPlan.paidCourts}</strong>
              </Typography>
              <Typography variant="body1">
                Free Courts: <strong>{selectedPlan.freeCourts}</strong>
              </Typography>
              <Typography variant="h6" sx={{ mt: 1 }}>
                Total Price: <strong>${selectedPlan.price} per month</strong>
              </Typography>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={selectedPlan.courtCount < 1 || !Number.isInteger(selectedPlan.courtCount)}
            >
              Sign Up
            </Button>
          </Box>
        </Box>
      )}

      {activeStep === 1 && (
        // Step 2: Collect User Details and Login Info
        <Box>
          <Typography variant="h5" gutterBottom>
            Your Details
          </Typography>
          <TextField
            label="First Name"
            name="firstName"
            value={userDetails.firstName}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={userDetails.lastName}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={userDetails.email}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone Number"
            name="phone"
            value={userDetails.phone}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Organization"
            name="organization"
            value={userDetails.organization}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Username"
            name="username"
            value={userDetails.username}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={userDetails.password}
            onChange={handleUserDetailsChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <Box sx={{ mt: 2 }}>
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
          </Box>
        </Box>
      )}

      {activeStep === 2 && (
        // Step 3: Payment using Paddle
        <Box>
          <Typography variant="h5" gutterBottom>
            Payment
          </Typography>
          {/* Display selected plan and price */}
          <Typography variant="body1" gutterBottom>
            You have selected <strong>{selectedPlan.courtCount} court(s)</strong>.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Paid Courts: <strong>{selectedPlan.paidCourts}</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Free Courts: <strong>{selectedPlan.freeCourts}</strong>
          </Typography>
          <Typography variant="h6" gutterBottom>
            Total Price: <strong>${selectedPlan.price} per month</strong>
          </Typography>
          {/* Trigger Paddle Checkout */}
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handlePaddleCheckout}>
              Proceed to Payment
            </Button>
          </Box>
        </Box>
      )}

      {activeStep === steps.length && (
        // Completion Message
        <Box>
          <Typography variant="h5" gutterBottom>
            Thank You!
          </Typography>
          <Typography variant="body1">
            Your sign-up is complete. We will contact you shortly.
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default SignUpPage;
