// src/pages/HomePage.js

import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import PeopleIcon from '@mui/icons-material/People';
import SpeedIcon from '@mui/icons-material/Speed';
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsIcon from '@mui/icons-material/Settings';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

// Testimonials Data
const testimonials = [
  {
    name: 'Nathaniel',
    location: 'Chicago',
    quote: 'Fives is a must-have for any pickup court. I always know the best times to play.',
  },
  {
    name: 'Andrew',
    location: 'Chicago',
    quote: 'I love how simple it is to use. No more disagreements about who plays next.',
  },
  {
    name: 'Jack',
    location: 'New York',
    quote: 'Having transparency about wait times has improved our games tremendously.',
  },
  {
    name: 'Riley',
    location: 'St. Louis',
    quote: 'Fives makes organizing games effortless and enjoyable.',
  },
  {
    name: 'Jackson',
    location: 'Ann Arbor',
    quote: 'No more confusion or disagreements. Fives keeps everything running smoothly.',
  },
  {
    name: 'Drew',
    location: 'Ann Arbor',
    quote: 'An essential tool for pickup basketball players. Highly recommended!',
  },
];

function HomePage() {
  // State for rotating testimonials
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  // Intersection Observers
  const [ctaRef1, ctaInView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref5, inView5] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ctaRef2, ctaInView2] = useInView({ triggerOnce: true, threshold: 0.1 });

  // Framer Motion Variants
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  // Rotate testimonials every 6 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  // Handlers for testimonial navigation
  const handlePrevTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNextTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Initialize Particles
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: { xs: 10, md: 15 },
          px: 2,
          color: '#0A1F44',
          minHeight: '100vh', // Ensure the hero section is at least the viewport height
        }}
      >
        {/* Particles confined to this section */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1, // Ensures particles stay behind content
          }}
        >
          <Particles
            id="tsparticles"
            init={particlesInit}
            options={{
              particles: {
                number: { value: 100, density: { enable: true, area: 800 } },
                size: { value: 3, random: true },
                move: { enable: true, speed: 1 },
                color: { value: ['#0A1F44', '#6c757d'] },
                opacity: { value: 0.5 },
              },
              interactivity: {
                events: {
                  onHover: { enable: true, mode: 'repulse' },
                  onClick: { enable: false },
                },
                modes: { repulse: { distance: 100 } },
              },
              detectRetina: true,
              background: {
                color: 'transparent',
              },
              fullScreen: false,
            }}
          />
        </Box>

        {/* Content of the Hero Section */}
        <motion.div
          ref={ctaRef1}
          variants={sectionVariants}
          initial="hidden"
          animate={ctaInView1 ? 'visible' : 'hidden'}
          transition={{ duration: 0.6 }}
        >
          <Container maxWidth="md">
            <Typography
              variant="h1"
              component="h1"
              gutterBottom
              sx={{ fontWeight: 'bold' }}
            >
              Pickup Basketball Done Better
            </Typography>
            <Typography variant="h5" component="p" gutterBottom sx={{ mb: 4 }}>
              Join games effortlessly. Shorter wait times. No more disagreements.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              component={Link}
              to="/signup"
              sx={{ mt: 2, mr: 2 }}
            >
              Bring Fives to your court today
            </Button>

            {/* Demo Video Section */}
            <Box sx={{ pt: 10, mt: 3, width: '100%', maxWidth: '800px' }}>
              <video
                src="/images/demo.mov" // Ensure this path is correct and the video is placed in public/images/
                controls
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '500px', // Optional: Set a maximum height
                  borderRadius: '8px',
                  objectFit: 'cover',
                  backgroundColor: '#000', // Optional for better presentation
                }}
              >
                Your browser does not support the video tag.
              </video>
            </Box>
          </Container>
        </motion.div>
      </Box>

      {/* Section 1: How Fives Works */}
      <motion.div
        ref={ref1}
        variants={sectionVariants}
        initial="hidden"
        animate={inView1 ? 'visible' : 'hidden'}
        transition={{ duration: 1.6 }}
      >
        <Container maxWidth="md" sx={{ py: 8 }}>
          <Typography variant="h3" gutterBottom align="center">
            How Fives Works
          </Typography>
          <Typography variant="h5" align="center" paragraph>
            Fives makes it easy to join and organize pickup basketball games. Here's how:
          </Typography>
        </Container>
      </motion.div>

      {/* Section 2: For Players */}
      <motion.div
        ref={ref2}
        variants={sectionVariants}
        initial="hidden"
        animate={inView2 ? 'visible' : 'hidden'}
        transition={{ duration: 1.6 }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom align="center" sx={{ mt: 8, mb: 4 }}>
            For Players
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <QrCodeIcon color="primary" sx={{ fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  1. Scan the QR Code
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Scan the QR code for the court you want to play on, or search for it on our website.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <PeopleIcon color="primary" sx={{ fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  2. Join the Next Team
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Join the next team as an individual or with friends.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <SportsBasketballIcon color="primary" sx={{ fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  3. Play the Game We Love
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Get on the court faster and enjoy playing basketball without the hassle.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      {/* Section 3: For Court Administrators */}
      <motion.div
        ref={ref3}
        variants={sectionVariants}
        initial="hidden"
        animate={inView3 ? 'visible' : 'hidden'}
        transition={{ duration: 1.6 }}
      >
        <Container maxWidth="md">
          <Typography variant="h4" gutterBottom align="center" sx={{ mt: 10, mb: 4 }}>
            For Court Administrators
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <SettingsIcon color="primary" sx={{ fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  1. Add Your Court
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Register your court and print out your unique QR code.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <SpeedIcon color="primary" sx={{ fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  2. Set Availability & Rules
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Customize your court's availability and game rules.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <ThumbUpIcon color="primary" sx={{ fontSize: 60 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  3. Manage Court Traffic
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Say goodbye to disagreements and improve court management.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </motion.div>

      {/* Testimonials Section */}
      <motion.div
        ref={ref5}
        variants={sectionVariants}
        initial="hidden"
        animate={inView5 ? 'visible' : 'hidden'}
        transition={{ duration: 1.6 }}
      >
        <Box sx={{ pt: 12, pb: 1, backgroundColor: '#FFFFFF' }}>
          <Container maxWidth="sm">
            <Typography variant="h3" gutterBottom align="center">
              What Players Are Saying
            </Typography>
            <Card elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Button onClick={handlePrevTestimonial} sx={{ minWidth: 'auto' }}>
                    <ArrowBackIosIcon />
                  </Button>
                  <Box sx={{ flex: 1, mx: 2 }}>
                    <Typography variant="h6" align="center">
                      {testimonials[currentTestimonialIndex].name}
                    </Typography>
                    <Typography variant="body2" align="center">
                      {testimonials[currentTestimonialIndex].location}
                    </Typography>
                    <Typography variant="body1" align="center">
                      "{testimonials[currentTestimonialIndex].quote}"
                    </Typography>
                  </Box>
                  <Button onClick={handleNextTestimonial} sx={{ minWidth: 'auto' }}>
                    <ArrowForwardIosIcon />
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </motion.div>

      {/* Final CTA */}
      <motion.div
        ref={ctaRef2}
        variants={sectionVariants}
        initial="hidden"
        animate={ctaInView2 ? 'visible' : 'hidden'}
        transition={{ duration: 1.6 }}
      >
        <Box sx={{ pt: 5, pb: 10, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/signup"
            sx={{ borderRadius: 8 }}
          >
            Bring Fives to Your Court Today
          </Button>
        </Box>
      </motion.div>

      {/* Footer */}
      <footer>
        <Box sx={{ bgcolor: 'primary.main', color: '#fff', py: 3 }}>
          <Container maxWidth="md">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Fives</Typography>
                <Typography variant="body2">
                  Connecting basketball players to the games they love.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/contact"
                  sx={{ borderRadius: 8 }}
                >
                  Contact Us
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </footer>
    </Box>
  );
}

export default HomePage;
