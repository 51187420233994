// src/pages/ContactUsPage.js

import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Alert } from '@mui/material';
import emailjs from 'emailjs-com';

function ContactUsPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, subject, message } = formData;

    // Validation (optional but recommended)
    if (!name || !email || !subject || !message) {
      setAlert({ type: 'error', message: 'All fields are required.' });
      return;
    }

    // Create email parameters matching the EmailJS template
    const emailParams = {
      from_name: name,         // Sender's name
      from_email: email,       // Sender's email
      subject: subject,        // Subject of the message
      message: message,        // Message content
      to_name: 'Recipient Name', // Optional: recipient name or a static name
    };

    // Send email using EmailJS
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Template ID
        emailParams, // Form data mapped to template variables
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Public Key
      )
      .then(
        (result) => {
          console.log('Email successfully sent!', result.text);
          setAlert({ type: 'success', message: 'Message sent successfully!' });
          setFormData({ name: '', email: '', subject: '', message: '' }); // Clear form
        },
        (error) => {
          console.error('Email sending error:', error);
          setAlert({ type: 'error', message: 'Failed to send message. Please try again later.' });
        }
      );
  };

  return (
    <Container maxWidth="sm" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      {alert.message && (
        <Alert severity={alert.type} sx={{ mb: 2 }}>
          {alert.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          required
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Send Message
        </Button>
      </form>
    </Container>
  );
}

export default ContactUsPage;
