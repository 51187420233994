// frontend/src/utils/api.js
import axios from 'axios';

const API_BASE_URL =
  process.env.REACT_APP_API_URL || // Use environment variable in deployment
  (process.env.NODE_ENV === 'production'
    ? 'https://fives.onrender.com/api' // Production backend URL
    : 'http://localhost:5001/api'); // Development backend URL

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});


// Get queue status for a specific court
export const getQueueStatus = async (courtId) => {
    const response = await apiClient.get(`/queue/status/${courtId}`);
    return response.data;
};

// Join a single slot in the queue (updated to include playerId and userLocation)
export const joinQueue = async (courtId, teamIndex, slotIndex, name, playerId, userLocation) => {
  const response = await apiClient.post('/queue/join', {
    courtId,
    teamIndex,
    slotIndex,
    name,
    playerId,
    userLocation,  // Include user location if necessary for geo-verification
  });
  return response.data;
};

// Add a new team to the queue
export const addTeamToQueue = async (courtId) => {
  const response = await apiClient.post('/queue/addTeam', {
    courtId,
  });
  return response.data;
};



// frontend/src/utils/api.js

export const removeEntryFromQueue = async (courtId, teamIndex, slotIndex, playerId) => {
  const response = await apiClient.post(`/queue/remove`, {
    courtId,
    teamIndex,
    slotIndex,
    playerId,
  });
  return response.data;
};


// Report top team as absent
export const reportTopTeamAbsent = async (courtId) => {
  const response = await apiClient.post('/queue/reportAbsent', {
    courtId,
  });
  return response.data;
};

// Join a group in the queue
export const joinGroupQueue = async (courtId, groupNames,playerId, userLocation) => {
    const response = await apiClient.post(`/queue/joinGroup`, {
      courtId,
      groupNames,
      playerId,
      userLocation, 
      // Include playerId and userLocation if needed for group verification
    });
    return response.data;
};

// Confirm team presence
export const confirmTeamPresent = async (courtId, teamIndex) => {
    const response = await apiClient.post('/queue/confirmPresent', {
      courtId,
      teamIndex,
    });
    return response.data;
};

// Start a game

// Start a game by removing a specific team
export const startGame = async (courtId, teamIndex, playerId) => {
  const response = await apiClient.post('/queue/startGame', {
    courtId,
    teamIndex,
    playerId,
  });
  return response.data;
};


// Get recently removed teams
export const getRemovedTeams = async (courtId) => {
  const response = await apiClient.get(`/queue/removedTeams/${courtId}`);
  return response.data;
};

// Restore a removed team
export const restoreTeam = async (removedTeamId) => {
  const response = await apiClient.post('/queue/restoreTeam', {
    removedTeamId,
  });
  return response.data;
};

export default apiClient;
