// frontend/src/pages/CourtQueuePage.js

import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Card,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  getQueueStatus,
  joinQueue,
  addTeamToQueue,
  removeEntryFromQueue,
  reportTopTeamAbsent,
  confirmTeamPresent,
  startGame,
  getRemovedTeams,
  restoreTeam,
  joinGroupQueue,
} from '../utils/api';
import { useParams } from 'react-router-dom';
import { getPlayerId } from '../utils/playerId';
import { initiateSocket, subscribeToQueueUpdates, disconnectSocket } from '../utils/socket';

function CourtQueuePage() {
  const { courtId } = useParams();
  const [queue, setQueue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [userEntries, setUserEntries] = useState([]);
  const [removedTeams, setRemovedTeams] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [slotInputs, setSlotInputs] = useState({});
  const [groupNames, setGroupNames] = useState('');

  const playerId = getPlayerId();

  useEffect(() => {
    if (courtId) {
      initiateSocket(courtId);  // Establish WebSocket connection
      subscribeToQueueUpdates((updatedQueue) => {
        console.log('Queue update received:', updatedQueue);
        setQueue(updatedQueue);  // Update queue when changes are received
      });
    }

    return () => {
      disconnectSocket();  // Cleanup WebSocket on component unmount
    };
  }, [courtId]);

  // Geolocation: Request user's location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          setUserLocation(location);
          console.log('User location obtained:', location);
        },
        (error) => {
          console.error('Error getting location:', error);
          setError('Unable to retrieve your location. Location-based features will not work.');
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setError('Geolocation is not supported by your browser.');
    }
  }, []);

  // Fetch initial queue and removed teams
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const data = await getQueueStatus(courtId);
        if (!data.isOpen) {
          setError(data.message || 'Court is closed.');
          setQueue(null);
          setSchedule(data.schedule || []);
        } else {
          if (!data.queue || !data.queue.courtId) {
            throw new Error('Invalid queue data received from the server.');
          }
          setQueue(data.queue);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching queue:', err);
        setError(err.message || 'Failed to load queue.');
        setLoading(false);
      }
    };

    const fetchRemovedTeamsData = async () => {
      try {
        const response = await getRemovedTeams(courtId);
        setRemovedTeams(response.removedTeams);
      } catch (err) {
        console.error('Error fetching removed teams:', err);
      }
    };

    fetchInitialData();
    fetchRemovedTeamsData();
  }, [courtId]);

  // Update userEntries whenever queue changes
  useEffect(() => {
    if (queue) {
      // Update user entries based on the latest queue data
      const userTeams = queue.teams
        .map((team, teamIndex) => {
          const playerSlots = team.slots
            .map((slot, slotIndex) => (slot.playerId === playerId ? slotIndex : null))
            .filter(index => index !== null);
          if (playerSlots.length > 0) {
            return { teamIndex, slotIndices: playerSlots };
          }
          return null;
        })
        .filter(entry => entry !== null);
      setUserEntries(userTeams);
    } else {
      setUserEntries([]);
    }
  }, [queue, playerId]);

  // Update slotInputs whenever queue changes
  useEffect(() => {
    if (queue) {
      const initialSlotInputs = {};
      queue.teams.forEach((team, teamIndex) => {
        initialSlotInputs[teamIndex] = team.slots.map(slot => slot.name || '');
      });
      setSlotInputs(initialSlotInputs);
    }
  }, [queue]);





  // Initialize Socket.IO and subscribe to updates


  // Handler to remove player from the queue
  const handleRemovePlayer = async (teamIndex, slotIndex) => {
    try {
      const data = await removeEntryFromQueue(courtId, teamIndex, slotIndex, playerId);
      setQueue(data.queue);
      setError('');
      setSuccess('Player removed successfully.');

      // Update user entries
      setUserEntries(prev => prev.filter(entry => {
        if (entry.teamIndex === teamIndex) {
          return !entry.slotIndices.includes(slotIndex);
        }
        return true;
      }));
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.error || 'Failed to remove from the queue.');
      setSuccess('');
    }
  };

  // Handler to add a new team to the queue
  const handleAddTeam = async () => {
    try {
      const data = await addTeamToQueue(courtId);
      setQueue(data.queue);
      setError('');
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.error || 'Failed to add a new team.');
    }
  };

  // Handler to start a game (remove team from the queue)
  const handleStartGame = async (teamIndex) => {
    try {
      const data = await startGame(courtId, teamIndex, playerId);
      setQueue(data.queue);  // Update the queue state
  
      // Check if the queue is empty after removing the team
      if (data.queue.teams.length === 0) {
        await handleAddTeam(); // Automatically add a new team if no teams are left
      }
  
      setUserEntries([]);
      setError('');
      setSuccess('Game started successfully.');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to start the game.');
    }
  };

  // Handler to confirm team presence
  const handleConfirmPresent = async (teamIndex) => {
    try {
      const data = await confirmTeamPresent(courtId, teamIndex);
      setQueue(data.queue);
      setError('');
      setSuccess('Team presence confirmed.');
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.error || 'Failed to confirm presence.');
      setSuccess('');
    }
  };

  // Handler to report team as absent
  const handleReportAbsent = async (teamIndex) => {
    try {
      const data = await reportTopTeamAbsent(courtId);
      setQueue(data.queue);
      setError('');
      setSuccess('Team reported as absent.');
    } catch (err) {
      console.error(err);
      setError('Failed to report the team as absent.');
      setSuccess('');
    }
  };

  // Handler to restore a removed team
  const handleRestoreTeam = async (removedTeamId) => {
    try {
      const data = await restoreTeam(removedTeamId);
      setQueue(data.queue);
      setRemovedTeams(data.removedTeams); // Update removed teams list
      setError('');
      setSuccess('Team restored successfully.');
    } catch (err) {
      console.error('Failed to restore team:', err);
      setError('Failed to restore the team.');
      setSuccess('');
    }
  };

  // Handler to add players to team
  const handleAddPlayersToTeam = async (teamIndex) => {
    const teamSlotInputs = slotInputs[teamIndex];
    if (!teamSlotInputs) return;
  
    const namesToAdd = teamSlotInputs.map((name, slotIndex) => {
      const slot = queue.teams[teamIndex].slots[slotIndex];
      if (!slot.name && name.trim() !== '') {
        return { index: slotIndex, name: name.trim() };
      }
      return null;
    }).filter(entry => entry !== null);
  
    if (namesToAdd.length === 0) {
      setError('Please enter at least one name to add.');
      return;
    }
  
    try {
      const allNames = namesToAdd.map(entry => joinQueue(courtId, teamIndex, entry.index, entry.name, playerId, userLocation));
      await Promise.all(allNames); // Wait for all player additions to complete
      
      // Automatically refresh queue after adding all players
      const updatedQueue = await getQueueStatus(courtId);
      setQueue(updatedQueue.queue); // Update the queue in one go
  
      setError('');
      setSuccess('Players successfully added to the team.');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to add players to the team.');
    }
  };

  // Handler to join a group
  const handleJoinGroup = async () => {
    // Split group names by commas and trim spaces
    const groupNamesArray = groupNames.split(',').map(name => name.trim()).filter(name => name !== '');

    if (groupNamesArray.length === 0 || groupNamesArray.length > 5) {
      setError('Group must have between 1 and 5 names.');
      return;
    }

    if (!userLocation) {
      setError('User location is required.');
      return;
    }

    try {
      const data = await joinGroupQueue(courtId, groupNamesArray, playerId, userLocation);
      setQueue(data.queue);
      setError('');
      setSuccess('Group successfully joined the queue.');
      setGroupNames('');
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.error || 'Failed to join the group.');
      setSuccess('');
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error && !queue) { // Display error only if queue is null
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
        {schedule.length > 0 && (
          <>
            <Typography variant="subtitle1">Court Schedule:</Typography>
            {schedule.map(s => (
              <Typography key={s.dayOfWeek}>
                {getDayName(s.dayOfWeek)}: {s.openTime} - {s.closeTime}
              </Typography>
            ))}
          </>
        )}
      </Container>
    );
  }

  if (!queue) {
    return (
      <Container>
        <Typography variant="h6">Queue not found for this court.</Typography>
      </Container>
    );
  }

  // Determine the top team index
  const topTeamIndex = queue.teams.length > 0 ? 0 : null;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Queue for {queue.courtId.name}
      </Typography>
    
      {error && <Alert severity="error" style={{ marginBottom: '10px' }}>{error}</Alert>}
      {success && <Alert severity="success" style={{ marginBottom: '10px' }}>{success}</Alert>}

      <Grid container spacing={2}>
        {queue.teams.map((team, teamIndex) => {
          const isUserTeam = userEntries.some(entry => entry.teamIndex === teamIndex);
          const numPlayers = team.slots.filter(slot => slot.name).length;
          const isTopTeam = teamIndex === topTeamIndex;

          return (
            <Grid item xs={12} key={teamIndex}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${teamIndex}-content`}
                  id={`panel${teamIndex}-header`}
                >
                  <Typography variant="h6" style={{ flexGrow: 1 }}>
                    Team {teamIndex + 1} ({numPlayers}/5 {numPlayers === 1 ? 'player' : 'players'})
                  </Typography>

                  {/* Button Rendering Logic */}
                  {isTopTeam && isUserTeam && !team.reportedAbsent && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleStartGame(teamIndex)}
                      style={{ marginLeft: '10px' }}
                    >
                      Start Game
                    </Button>
                  )}
                  
                  {isTopTeam && isUserTeam && team.reportedAbsent && numPlayers > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleConfirmPresent(teamIndex)}
                      style={{ marginLeft: '10px' }}
                    >
                      We're Here
                    </Button>
                  )}

                  {isTopTeam && !isUserTeam && !team.reportedAbsent && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleReportAbsent(teamIndex)}
                      style={{ marginLeft: '10px' }}
                    >
                      Report Absent
                    </Button>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {team.slots.map((slot, slotIndex) => {
                    const isPlayerSlot = slot.playerId === playerId;
                    const canEdit = !slot.name || isPlayerSlot;
                    const slotInputValue = slotInputs[teamIndex] ? slotInputs[teamIndex][slotIndex] : slot.name || '';

                    return (
                      <div key={slotIndex} style={{ marginBottom: '10px' }}>
                        <TextField
                          label={`Player ${slotIndex + 1}`}
                          value={canEdit ? slotInputValue : slot.name}
                          onChange={(e) => {
                            if (canEdit) {
                              const value = e.target.value;
                              setSlotInputs(prev => {
                                const newInputs = { ...prev };
                                newInputs[teamIndex] = [...(newInputs[teamIndex] || [])];
                                newInputs[teamIndex][slotIndex] = value;
                                return newInputs;
                              });
                            }
                          }}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: !canEdit,
                          }}
                          style={{
                            backgroundColor: slot.name ? '#e0f7fa' : '#fff3e0',
                          }}
                        />
                        {slot.name && (
                          <Typography variant="body2">
                            Joined at: {new Date(slot.joinedAt).toLocaleTimeString()}
                          </Typography>
                        )}
                        {isPlayerSlot && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleRemovePlayer(teamIndex, slotIndex)}
                            style={{ marginTop: '5px' }}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                    );
                  })}
                  {/* Button to add players to team */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddPlayersToTeam(teamIndex)}
                    style={{ marginTop: '10px' }}
                    disabled={
                      // Disable if user is already in a different team
                      userEntries.length > 0 && !isUserTeam
                    }
                  >
                    Add Players to Team
                  </Button>
                  {!isUserTeam && userEntries.length > 0 && (
                    <Typography variant="body2" color="textSecondary">
                      You are already part of another team. You can only join additional slots in your current team.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          );
        })}
      </Grid>

      {/* Add Team Button */}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddTeam}
        >
          Add Team to Bottom
        </Button>
      </div>

      {/* Recently Removed Teams */}
      {removedTeams.length > 0 && (
        <div>
          <Typography variant="h6" style={{ marginTop: '20px' }}>Reported Absent Teams</Typography>
          {removedTeams.filter(team => team.team.reportedAbsent).length === 0 && (
            <Typography variant="body2" color="textSecondary">No teams have been reported absent in the last 15 minutes.</Typography>
          )}
          {removedTeams.filter(team => team.team.reportedAbsent).map((removedTeam, index) => (
            <Card key={index} style={{ marginTop: '10px' }}>
              <CardContent>
                <Typography variant="subtitle1">
                  Removed at: {new Date(removedTeam.removedAt).toLocaleTimeString()}
                </Typography>
                {removedTeam.team.slots.map((slot, slotIndex) => (
                  <Typography key={slotIndex}>
                    Player {slotIndex + 1}: {slot.name || 'Empty'}
                  </Typography>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRestoreTeam(removedTeam._id)}
                  style={{ marginTop: '10px' }}
                >
                  We're Here
                </Button>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </Container>
  );
}

export default CourtQueuePage;

// Helper function to get day name
function getDayName(dayIndex) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[dayIndex];
}
