// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, Button, Grid } from '@mui/material';
import axios from 'axios';

function AdminDashboard() {
  const [courts, setCourts] = useState([]);

  useEffect(() => {
    // Fetch courts managed by the admin
    const fetchAdminCourts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/courts`);
        setCourts(response.data.courts);
      } catch (error) {
        console.error('Error fetching admin courts:', error);
      }
    };

    fetchAdminCourts();
  }, []);

  const handleManageQueue = (courtId) => {
    // Redirect to a page or open a modal to manage the queue
  };

  const handleEditCourt = (courtId) => {
    // Redirect to a page or open a modal to edit court details
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>

      {/* Courts List */}
      <Grid container spacing={4}>
        {courts.map((court) => (
          <Grid item xs={12} sm={6} md={4} key={court._id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{court.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  Location: {court.location}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleManageQueue(court._id)}
                >
                  Manage Queue
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mt: 1 }}
                  onClick={() => handleEditCourt(court._id)}
                >
                  Edit Court Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default AdminDashboard;
