// frontend/src/utils/socket.js

import { io } from 'socket.io-client';

let socket;

/**
 * Initializes the Socket.IO client and connects to the server.
 * @param {String} courtId - The ID of the court to join.
 */
export const initiateSocket = (courtId) => {
  const backendURL = process.env.REACT_APP_API_URL || 'http://localhost:5001'; // Default to localhost for local development
  socket = io(backendURL);
  
  console.log('Connecting socket...');

  socket.on('connect', () => {
    console.log('Connected to socket server');
    socket.emit('joinCourt', courtId); // Join the specific court room
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from socket server');
  });
};

/**
 * Subscribes to queue updates for the court.
 * @param {Function} callback - The function to call when a queue update is received.
 */
export const subscribeToQueueUpdates = (callback) => {
  if (!socket) return;

  socket.on('queueUpdate', (data) => {
    console.log('Received queue update:', data);
    callback(data);
  });
};

/**
 * Disconnects the Socket.IO client.
 */
export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};
