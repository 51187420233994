// src/components/NavigationBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  useScrollTrigger,
  Slide,
  Box,
} from '@mui/material';

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NavigationBar(props) {
  return (
    <HideOnScroll {...props}>
      <AppBar position="sticky" color="transparent" elevation={0}>
        <Toolbar sx={{ borderBottom: '1px solid #E0E0E0' }}>
          {/* Company Logo */}
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/">
            <img
              src="/images/logo1.png"
              alt="Fives Logo"
              style={{
                height: 60, // Adjust height as needed
                objectFit: 'contain', // Ensures the logo scales proportionally
                padding: 4, // Optional: Adds padding around the image for better spacing
              }}
            />

            </Link>
          </Box>

          {/* Navigation Links */}
          <Box>
            <Button color="primary" component={Link} to="/about" sx={{ fontSize: '1rem', mx: 1 }}>
              Our Story
            </Button>
            <Button color="primary" component={Link} to="/courts" sx={{ fontSize: '1rem', mx: 1 }}>
              Courts
            </Button>
            <Button color="primary" component={Link} to="/signup" sx={{ fontSize: '1rem', mx: 1 }}>
              Sign Up
            </Button>
            <Button color="primary" component={Link} to="/admin" sx={{ fontSize: '1rem', mx: 1 }}>
              Admin Access
            </Button>
            <Button color="primary" component={Link} to="/contact" sx={{ fontSize: '1rem', mx: 1 }}>
              Contact Us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default NavigationBar;
