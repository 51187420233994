// frontend/src/utils/playerId.js
import { v4 as uuidv4 } from 'uuid';

export const getPlayerId = () => {
  let playerId = localStorage.getItem('playerId');
  if (!playerId) {
    playerId = uuidv4();
    localStorage.setItem('playerId', playerId);
  }
  return playerId;
};
