// src/theme.js
import { createTheme } from '@mui/material/styles';

// Create a custom theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: '#0A1F44', // Your preferred blue color
      contrastText: '#FFFFFF', // Ensure buttons have white text on primary color
    },
    secondary: {
      main: '#FFFFFF', // White for secondary elements
      contrastText: '#0A1F44', // Ensure buttons have dark text on white background
    },
    background: {
      default: '#FFFFFF', // White background
    },
    text: {
      primary: '#0A1F44', // Dark Blue text
      secondary: '#555555', // Gray text for secondary content
    },
    error: {
      main: '#ff1744', // Error color (red)
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif', // Modern, clean font
    h1: {
      fontWeight: 700,
      fontSize: '3rem', // Increased font size for hero message
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.75rem', // Increased from 1.5rem
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    body1: {
      fontSize: '1.125rem', // Increased from 1rem
      lineHeight: 1.8,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorTransparent: {
          backgroundColor: '#FFFFFF',
          color: '#0A1F44',
          borderBottom: '1px solid #E0E0E0', // Thin grey line
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 20px', // Make buttons bigger
          fontSize: '1rem',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          },
        },
        containedSecondary: {
          color: '#0A1F44', // Blue text
          backgroundColor: '#FFFFFF', // White background
          '&:hover': {
            backgroundColor: '#F5F5F5',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
});

export default theme;
