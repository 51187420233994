// src/pages/CourtsPage.js

import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import apiClient from '../utils/api';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function CourtsPage() {
  const [courts, setCourts] = useState([]);
  const [filteredCourts, setFilteredCourts] = useState([]);
  const [cities, setCities] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [expandedSchedule, setExpandedSchedule] = useState({}); // For "See Weekly Schedule"
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [userLatitude, setUserLatitude] = useState(null);
  const [userLongitude, setUserLongitude] = useState(null);

  useEffect(() => {
    fetchCourts();
  }, []);

  const fetchCourts = async (params = {}) => {
    setLoading(true);
    try {
      const response = await apiClient.get('/courts', { params });
      const courtsData = Array.isArray(response.data.courts) ? response.data.courts : [];

      console.log('Fetched Courts:', courtsData); // Debugging line

      setCourts(courtsData);
      setFilteredCourts(courtsData);

      // Extract unique cities and buildings for filtering
      const uniqueCities = [...new Set(courtsData.map(court => court.location.city).filter(Boolean))];
      setCities(uniqueCities);

      const uniqueBuildings = [...new Set(courtsData.map(court => court.building).filter(Boolean))];
      setBuildings(uniqueBuildings);

      setLoading(false);
    } catch (err) {
      console.error(err);
      setError('Failed to load courts.');
      setLoading(false);
    }
  };

  const handleCityChange = async (event, value) => {
    setSelectedCity(value);
    await fetchCourts({ city: value, building: selectedBuilding });
  };

  const handleBuildingChange = async (event, value) => {
    setSelectedBuilding(value);
    await fetchCourts({ city: selectedCity, building: value });
  };

  const toggleExpandedSchedule = (courtId) => {
    setExpandedSchedule((prevState) => ({
      ...prevState,
      [courtId]: !prevState[courtId],
    }));
  };

  const findClosestCourts = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUserLatitude(latitude);
          setUserLongitude(longitude);
          await fetchCourts({ latitude, longitude });
        },
        (err) => {
          console.error(err);
          setError('Unable to retrieve your location.');
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  if (loading) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ marginTop: '20px' }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  // Group courts by building
  const groupedCourts = buildings.map((building, index) => {
    // Filter courts by building
    const courtsInBuilding = filteredCourts.filter((court) => court.building === building);

    // Split courts into open and closed based on isCurrentlyOpen
    const openCourts = courtsInBuilding.filter((court) => court.isCurrentlyOpen);
    const closedCourts = courtsInBuilding.filter((court) => !court.isCurrentlyOpen);

    return {
      building: building || 'Other Courts',
      openCourts,
      closedCourts,
    };
  });

  // Handle cases where some courts might not have a building
  const otherCourts = filteredCourts.filter(court => !court.building);
  if (otherCourts.length > 0) {
    groupedCourts.push({
      building: 'Other Courts',
      openCourts: otherCourts.filter(court => court.isCurrentlyOpen),
      closedCourts: otherCourts.filter(court => !court.isCurrentlyOpen),
    });
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom style={{ marginTop: '20px' }}>
        Find a Court
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={findClosestCourts}
        style={{ marginBottom: '20px' }}
      >
        Find the Closest Court to Me
      </Button>

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={cities}
            value={selectedCity}
            onChange={handleCityChange}
            renderInput={(params) => <TextField {...params} label="Filter by City" variant="outlined" />}
            clearOnEscape
            freeSolo
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={buildings}
            value={selectedBuilding}
            onChange={handleBuildingChange}
            renderInput={(params) => <TextField {...params} label="Filter by Building" variant="outlined" />}
            clearOnEscape
            freeSolo
          />
        </Grid>
      </Grid>

      {groupedCourts.length > 0 ? (
        <div>
          {groupedCourts.map((group, index) => (
            <div key={index} style={{ marginBottom: '40px' }}>
              <Typography variant="h5" style={{ marginBottom: '10px' }}>
                {group.building}
              </Typography>

              {/* Open Courts Section */}
              {group.openCourts.length > 0 && (
                <>
                  <Typography variant="h6" style={{ marginBottom: '10px' }}>
                    Open Courts
                  </Typography>
                  <Grid container spacing={3}>
                    {group.openCourts.map((court) => (
                      <Grid item xs={12} sm={6} md={4} key={court._id}>
                        <CourtCard
                          court={court}
                          expanded={expandedSchedule[court._id]}
                          toggleExpandedSchedule={toggleExpandedSchedule}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {/* Closed Courts Section */}
              {group.closedCourts.length > 0 && (
                <>
                  <Typography variant="h6" style={{ marginTop: group.openCourts.length > 0 ? '30px' : '10px', marginBottom: '10px' }}>
                    Closed Courts
                  </Typography>
                  <Grid container spacing={3}>
                    {group.closedCourts.map((court) => (
                      <Grid item xs={12} sm={6} md={4} key={court._id}>
                        <CourtCard
                          court={court}
                          expanded={expandedSchedule[court._id]}
                          toggleExpandedSchedule={toggleExpandedSchedule}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}

              {/* Add Divider after each group, except the last one */}
              {index < groupedCourts.length - 1 && (
                <Divider style={{ margin: '40px 0', backgroundColor: '#E0E0E0' }} />
              )}
            </div>
          ))}
        </div>
      ) : (
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {userLatitude && userLongitude ? 'No courts within 15 miles.' : 'No courts found.'}
        </Typography>
      )}
    </Container>
  );
}

function CourtCard({ court, expanded, toggleExpandedSchedule }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{court.name}</Typography>
        {court.distance && (
          <Typography variant="body2" color="textSecondary">
            Distance: {court.distance.toFixed(2)} miles
          </Typography>
        )}
        <Typography variant="body2">
          Queue Length: {court.queueInfo.numTeams} teams, {court.queueInfo.numPlayers} {court.queueInfo.numPlayers === 1 ? 'player' : 'players'}
        </Typography>

        <Typography variant="body2" color="textSecondary">
          Today's Hours: {getTodaysHours(court)}
        </Typography>
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${court._id}-content`}
            id={`panel-${court._id}-header`}
            onClick={() => toggleExpandedSchedule(court._id)}
          >
            <Typography variant="body2" style={{ flexGrow: 1 }}>
              {expanded ? 'Hide Weekly Schedule' : 'See Weekly Schedule'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {court.schedule.map((s) => (
              <Typography key={s.dayOfWeek} variant="body2">
                {getDayName(s.dayOfWeek)}: {s.openTime} - {s.closeTime}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </CardContent>

      <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          component={Link}
          to={`/courts/${court._id}`}
          variant="contained"
          color="primary"
        >
          View Queue
        </Button>
      </CardContent>
    </Card>
  );
}

function getTodaysHours(court) {
  const dayOfWeek = new Date().getDay();
  const schedule = court.schedule.find((s) => s.dayOfWeek === dayOfWeek);
  if (schedule) {
    return `${schedule.openTime} - ${schedule.closeTime}`;
  }
  return 'Closed';
}

function getDayName(dayIndex) {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[dayIndex];
}

export default CourtsPage;
